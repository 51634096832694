import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export function useSearchText() {
  const router = useRouter();

  const searchText = ref('');

  onMounted(() => {
    const query = router.currentRoute.value.query.q;

    if (query) {
      searchText.value = query + '';
    }
  });

  watch(
    () => router.currentRoute.value.path,
    () => {
      if (!router.options.history.state.fromSearchForm) {
        searchText.value = '';
      }
    },
  );

  return { searchText };
}

export const isHexColor = (hexColor: string) => {
  const hex = hexColor.replace('#', '');

  return (
    hexColor.startsWith('#') && hex.length === 6 && !isNaN(Number('0x' + hex))
  );
};

export const hexToRgb = (hex: string) =>
  `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`;

export type NavListItem = {
  url: string;
  name: string;
  icon?: string | null;
  iconWidth?: number;
  iconHeight?: number;
};

export const navListItems: NavListItem[] = [
  {
    url: '/',
    name: 'Жанры',
    icon: 'genres',
  },
  {
    url: '/catalog/novelty',
    name: 'Новинки',
    icon: 'new',
  },
  {
    url: '/catalog/popular',
    name: 'Популярное',
    icon: 'popular',
    iconWidth: 26,
    iconHeight: 26,
  },
  {
    url: '/catalog/audio',
    name: 'Аудиокниги',
    icon: 'audio',
    iconHeight: 23,
  },
  {
    url: '/catalog/popular',
    name: 'Что почитать?',
    icon: 'book-coffee',
  },
  {
    url: '/catalog/discounts',
    name: 'Скидки',
    icon: 'discount',
  },
  {
    url: '/',
    name: 'Издательства',
    icon: 'publisher',
  },
];

export const ImageSize = {
  small: 1,
  medium: 2,
  large: 3,
  original: 'orig',
};

export enum ImageFormat {
  AVIF = 1,
  WEBP = 2,
  JPG = 3,
  PNG = 4,
}

export type ImgAttributes = {
  width?: number;
  alt?: string;
  title?: string;
  loading?: 'lazy';
};

export type ImageScale = 1 | 2 | 3;

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.book.authors, (author, index) => {
    return (_openBlock(), _createBlock(_component_router_link, {
      key: author.id,
      to: _ctx.authorUrl(author.id),
      class: _normalizeClass(_ctx.linkClass)
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(author.name), 1),
        (index < _ctx.book.authors.length - 1)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(", ")
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, 1032, ["to", "class"]))
  }), 128))
}
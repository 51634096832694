import { ref, type Ref } from 'vue';
import { useRouter, type LocationQueryRaw } from 'vue-router';

export function useSubmit(searchText: Ref<string>) {
  const router = useRouter();

  const submitting = ref(false);

  const pushTo = (path: string, query: LocationQueryRaw) => {
    return router.push({
      path,
      query,
      state: {
        fromSearchForm: true,
      },
    });
  };

  const toSuggestedUrl = (url: string) => {
    const [path, queryStr] = url.split('?');
    const query = Object.fromEntries(new URLSearchParams(queryStr));

    return pushTo(path, query);
  };

  const toSearchPage = () => {
    return pushTo('/search', {
      q: searchText.value,
    });
  };

  const onSubmit = async (url: string | null = null) => {
    if (searchText.value.length >= 3) {
      submitting.value = true;

      if (url) {
        await toSuggestedUrl(url);
      } else {
        await toSearchPage();
      }

      // delay state change to prevent unwanted autocomplete occurring
      setTimeout(() => (submitting.value = false), 500);
    }
  };

  return { submitting, onSubmit };
}

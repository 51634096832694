import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-table" }
const _hoisted_2 = { class: "v-table__thead" }
const _hoisted_3 = { class: "v-table__row" }
const _hoisted_4 = { class: "v-table__tbody" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", _hoisted_2, [
      _createElementVNode("tr", _hoisted_3, [
        _renderSlot(_ctx.$slots, "thead")
      ])
    ]),
    _createElementVNode("tbody", _hoisted_4, [
      _renderSlot(_ctx.$slots, "tbody")
    ])
  ]))
}
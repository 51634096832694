export interface BookAuthor {
  id: number;
  name: string;
  url: string;
}

export interface Grade {
  value: string;
  count: number;
}

export interface Price {
  price: number;
  basePrice?: number;
}

export enum EVariationType {
  AUDIO = 'AUDIO',
  TEXT = 'TEXT',
  PDF = 'PDF',
  PAPER = 'PAPER',
  DRAFT = 'DRAFT',
}

export type VariationType = `${EVariationType}`;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_BookImg = _resolveComponent("BookImg")!

  return (_ctx.suggestion.type == _ctx.SuggestionType.AUTHOR)
    ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        class: "search__author",
        icon: "no-avatar",
        width: "18",
        height: "18"
      }))
    : (_ctx.suggestion.type == _ctx.SuggestionType.PUBLISHER)
      ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 1,
          class: "search__publisher",
          icon: "books-stack",
          width: "18",
          height: "16"
        }))
      : (_ctx.suggestion.type == _ctx.SuggestionType.BOOK && _ctx.suggestion.img)
        ? (_openBlock(), _createBlock(_component_BookImg, {
            key: 2,
            width: "18",
            src: _ctx.suggestion.img,
            "slot-type": "autocomplete",
            class: "search__img"
          }, null, 8, ["src"]))
        : _createCommentVNode("", true)
}
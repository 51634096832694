import {
  EVariationType,
  type VariationType,
} from '@/components/types/book_types';

export type IconParams = {
  icon: string;
  width: number;
  height: number;
};

export const iconParams: Record<VariationType, IconParams> = {
  [EVariationType.AUDIO]: {
    icon: 'buy-audio',
    width: 28,
    height: 27,
  },
  [EVariationType.TEXT]: {
    icon: 'buy-ebook',
    width: 21,
    height: 29,
  },
  [EVariationType.PDF]: {
    icon: 'buy-pdf',
    width: 27,
    height: 29,
  },
  [EVariationType.PAPER]: {
    icon: 'buy-paper',
    width: 28,
    height: 22,
  },
  [EVariationType.DRAFT]: {
    icon: 'buy-paper',
    width: 28,
    height: 22,
  },
};

export const SuggestionType = {
  AUTHOR: 1,
  BOOK: 2,
  PUBLISHER: 3,
} as const;

export type Suggestion = {
  title: string;
  url: string;
  type: (typeof SuggestionType)[keyof typeof SuggestionType];
  img?: string | null;
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search__autocomplete reset-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderSuggestion = _resolveComponent("HeaderSuggestion")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_HeaderSuggestion, {
        key: item.url,
        search: _ctx.search,
        suggestion: item,
        "is-active": _ctx.arrowIndex == index,
        onClick: ($event: any) => (_ctx.$emit('select', item.url))
      }, null, 8, ["search", "suggestion", "is-active", "onClick"]))
    }), 128))
  ]))
}
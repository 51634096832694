import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-list__item user-list__item--cart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuestLoginLink = _resolveComponent("GuestLoginLink")!
  const _component_UserLoginLink = _resolveComponent("UserLoginLink")!
  const _component_UserProfileList = _resolveComponent("UserProfileList")!
  const _component_UserCartBtn = _resolveComponent("UserCartBtn")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["user-list reset-list", { 'user-list--hidden': !_ctx.showMenu }])
  }, [
    _createElementVNode("li", {
      class: _normalizeClass(["user-list__item user-list__item--login", { 'user-list__item--guest': _ctx.isMobileGuestLink }])
    }, [
      (_ctx.isMobileGuestLink)
        ? (_openBlock(), _createBlock(_component_GuestLoginLink, {
            key: 0,
            class: "user-list__link flex-center-v",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
          }))
        : (_openBlock(), _createBlock(_component_UserLoginLink, {
            key: 1,
            class: "user-list__link flex-center-v",
            "show-profile-menu": _ctx.showProfileMenu,
            onToggleProfileMenu: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showProfileMenu = !_ctx.showProfileMenu)),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click')))
          }, null, 8, ["show-profile-menu"]))
    ], 2),
    _createElementVNode("li", {
      class: _normalizeClass(["user-list__item user-list__item--sublist", { 'user-list__item--visible': _ctx.showProfileMenu }])
    }, [
      _createVNode(_component_UserProfileList, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showProfileMenu = false))
      })
    ], 2),
    _createElementVNode("li", _hoisted_1, [
      _createVNode(_component_UserCartBtn, {
        class: "user-list__link flex-center-v",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('click')))
      })
    ])
  ], 2))
}
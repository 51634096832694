import { withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search__bar-wrap" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderAutocomplete = _resolveComponent("HeaderAutocomplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      value: _ctx.searchText,
      type: "search",
      class: "search__bar",
      name: "q",
      placeholder: "Название, автор, издательство, ISBN",
      minlength: "3",
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      onKeydown: [
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onArrowDown && _ctx.onArrowDown(...args)), ["prevent"]), ["down"])),
        _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onArrowUp && _ctx.onArrowUp(...args)), ["prevent"]), ["up"])),
        _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (_ctx.onEnter($event)), ["prevent"]), ["enter"]))
      ],
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.resetCursor && _ctx.resetCursor(...args)))
    }, null, 40, _hoisted_2),
    _withDirectives(_createVNode(_component_HeaderAutocomplete, {
      search: _ctx.searchText,
      items: _ctx.suggestions,
      "arrow-index": _ctx.arrowIndex,
      onSelect: _ctx.submit
    }, null, 8, ["search", "items", "arrow-index", "onSelect"]), [
      [_vShow, _ctx.isShowAutocomplete]
    ])
  ]))
}
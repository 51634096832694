import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["datetime"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message", _ctx.customClass])
  }, [
    _createElementVNode("time", {
      datetime: _ctx.machineDateTime,
      class: "message__date"
    }, _toDisplayString(_ctx.humanDateTime), 9, _hoisted_1),
    _createElementVNode("p", {
      class: "message__text",
      innerHTML: _ctx.messageSafeHtml
    }, null, 8, _hoisted_2)
  ], 2))
}